:root{
	--primary-color: #89aba6;
	--success-color: #AB8033;
	--error-color: #8C402D;
	--text-color-2: #383E40;

	--bg-color: #f0f2f5;
	--navbar-color: #ffffff;

	--subnav-color: #efefef;


	--highlight-level-one: #eee;
	--highlight-level-two: #e0e0e0;

	--divider-color: rgba(0, 0, 0, .12);

	--max-content-width: 1200px;

}


html, body, #root {
	background-color: var(--bg-color);
	scroll-padding-top: 55px;
}

.webcontainer ul, ol, p {
	/*
	color: var(--text-color-2);
	*/
	font-size: 14px;
	line-height: 22px !important;

  margin: revert;
}



.webcontainer .features-article a, .webcontainer .faqcontent a {
  color: revert;
  text-decoration: underline;
}

/*
 * Should move padding to section?
 */
body, #root {
	margin: 0px;

	font-family: Segoe UI;
	height: 100vh;
	display: flex;
	flex-direction:column;

  font-size: 16px;
}

.webcontainer .navheader * {
  font-size: 16px;

}
.webcontainer .navbar {
	background-color: var(--navbar-color);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	line-height: 55px !important;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12);
}

/*
 * Used to style anchors in nav
 */
.webcontainer .navbar a {
	padding: 17px 10px;
	text-decoration: none;
	color: black;
	transition: all .3s,padding 0s, border .1s, width .3s cubic-bezier(.2,0,0,1) 0s;
}

/*
 * Used for hiding dropdown
 */
.webcontainer .navbar > div {
	overflow: hidden;
	display: inline;
}

.webcontainer .loginhero {
	display: none;
	margin-top: 20px;

}
.webcontainer .loginhero > a {
	padding: 15px 20px;
	background-color: white;
	margin: 5px 10px;

	text-decoration: none;

	flex: 1 1 0px;
	max-width: 75px;

	border-radius: 5px;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12);

	font-size: 14px;
	line-height: 22px !important;

	color: black;
}

@media screen and (min-width:801px) {
	.webcontainer .navbar > :last-child { display: none; }

	.webcontainer .navbar a.active {
		padding: 16px 10px;
		border-bottom: solid 2px var(--primary-color);
	}

	/*
	 * Highlight nav btns on hover (including ones that have dropdowns)
	 * switch .subnav a to nav > div >a:hover to make it only highlight on its hover
	 */
	.webcontainer .navbar > a:hover, .subnav:hover a, .navbar a:hover{
		background-color: var(--highlight-level-one);
	}

	.webcontainer .navbar > a:hover, .navbar > div > a:hover, .navbar a:hover {
		padding: 16px 10px;
		border-bottom: solid 2px var(--primary-color);
	}



	/*
	 * Highlight dropdown on hover
	 */
	.webcontainer .subnav-content a:hover {
		background-color: var(--highlight-level-two);
	}

	.webcontainer .subnav:hover .subnav-content {
		height: 55px;
	}
}

@media screen and (min-width:501px) and (max-width:800px) {
	/*
	 * Navbar stuff (same as small screen)
	 */
	.webcontainer .navbar > :first-child {
		max-width: 40px !important;
	}
	.webcontainer .navbar > .loginnav {display: none;}

	.webcontainer .navbar .subnav-content {display: none;}

	.webcontainer .navbar > .subnav > a{
		display: inherit;
	}

	.webcontainer .navbar > .subnav {
		overflow: initial;
	}

	.webcontainer .navbar > :not(:first-child).active,
	.webcontainer .navbar > :not(:first-child) .active {
		background-color: var(--highlight-level-one);
	}


	.webcontainer .navbar {
		transition: height 1s cubic-bezier(.2,0,0,1) 0s;
		height: 55px;
		overflow: hidden;

		display: flex;
		flex-direction: column;
	}

	.webcontainer .navbar.responsive {
		height: 350px;
	}

	.webcontainer .navbar a {
		display: inline-block;
		box-sizing: border-box;
		line-height: 100% !important;
		max-height: 55px;
		min-height: 55px;
	}

	.webcontainer .logoImage {
		margin-top: -5px !important;
	}

	/*
	 * Display login btns
	 */
	.webcontainer .hero {
		padding-left: 0px !important;

		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.webcontainer .loginhero {
		display: inline-flex;
		justify-content: center;
	}


	.webcontainer .hero > img {
		width: 500px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: 20px !important;

	}

	.webcontainer .features-hero {
		flex: auto !important;
		overflow: clip !important;
	}
	.webcontainer .features-hero img {
		width: 520px !important;
	}

	.webcontainer .features-right-hero > img {
		margin-left: -200px !important;
	}
	.webcontainer .features-hero > .features-article {
		max-width: 250px !important;
	}

	.webcontainer .update-card {
		min-height: auto !important;
	}
}

@media screen and (max-width: 500px) {

	.webcontainer .navbar > :first-child {
		max-width: 40px !important;
	}
	.webcontainer .navbar > .loginnav {display: none;}

	.webcontainer .navbar .subnav-content {display: none;}

	.webcontainer .navbar > .subnav > a{
		display: inherit;
	}

	.webcontainer .navbar > .subnav {
		overflow: initial;
	}

	.webcontainer .navbar > :not(:first-child).active,
	.webcontainer .navbar > :not(:first-child) .active {
		background-color: var(--highlight-level-one);
	}

	.webcontainer .navbar {
		transition: height 1s cubic-bezier(.2,0,0,1) 0s;
		height: 55px;
		overflow: hidden;

		display: flex;
		flex-direction: column;
	}

	.webcontainer .navbar.responsive {
		height: 350px;
	}

	.webcontainer .navbar a {
		display: inline-block;
		box-sizing: border-box;
		line-height: 100% !important;
		max-height: 55px;
		min-height: 55px;
	}

	.webcontainer .logoImage {
		margin-top: -5px !important;
	}

	/*
	 * Home screen specific
	 */
	.webcontainer .hero > img {
		display: none !important;

	}

	.webcontainer .hero {
		padding-left: 0px !important;
		flex: 0 0 270px !important;

		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.webcontainer .loginhero {
		display: inline-flex;
		justify-content: center;
	}

	/*
	 * Features page specific
	 */
	.webcontainer .features-hero {
		padding-right: 50px !important;
		flex: auto !important;
		overflow: clip !important;
	}

	.webcontainer .features-hero > img {
		display: none !important;
	}

	.webcontainer .features-right-hero > .features-article {
		text-align: left !important;
		margin-left: 0px !important;
	}

	/*
	 * FAQs page
	 */
	.webcontainer aside {
		display: none !important;
	 }

	 /*
	  * Updates page
	  */
	.webcontainer .update-card {
		min-height: auto !important;
	}

	.webcontainer section.centeredcontent {
		padding: 10px 20px 10px 20px !important;
	}
}

.webcontainer header.navheader {
	margin-bottom: 55px;
}

/*
 * Used to keep content around the center of the screen while preserving a max size
 */
.webcontainer section.centeredcontent {
	padding: 10px 40px 10px 40px;

	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
}

.webcontainer section.capcontentwidth {
	max-width: var(--max-content-width);
	width: 100%;
}

.webcontainer h1 {

	text-align: center;
	font-size: 30px;
	font-weight: 600;
  margin: revert;
}

.webcontainer h2 {
	font-size: 20px;
	font-weight: 600;

  margin: revert;
}

.webcontainer h3 {
	font-weight: 60;
  margin: revert;
}

.webcontainer h4 {
	font-weight: 80;
	border-bottom: 1px solid var(--divider-color);
  margin: revert;
}

.webcontainer p {
  color: black;
  margin: revert;
}

.webcontainer .subnav-content {
	height: 0px;
	overflow: hidden;
	position: absolute;
	left: 0;
	background-color: var(--subnav-color);
	width: 100%;
	z-index: -1;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12);
	transition: all .4s,width .3s cubic-bezier(.2,0,0,1) 0s;
}

/*
 * Used to style image in nav
 */
.webcontainer .logoImage {
	height: 30px;
	vertical-align: middle;
	margin: auto;
}

/*
 * Used to move div to the right side of nav bar
 */
.webcontainer .navright {
	display: inline;
	right: 30px;
	position: absolute;
  line-height: inherit;
}


/*
 * Used to style footer
 */
.webcontainer footer {
	margin-top:auto;
	width: 100%;
	font-size: 14px;
	/*
	background-color: #333;
	*/
	/*
	background-color: var(--bg-color);
	*/
	background-color: var(--bg-color);
	color: black;
	text-align: center;

	border-top: 1px solid var(--divider-color);

  display: initial;
}

.webcontainer .faq-grid {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, 200px);

	grid-auto-rows: auto;

	grid-gap: 1rem;
	font-size: 120px;
}

.webcontainer .faq-card p {
  color: var(--text-color-2) !important;
}
.webcontainer .faq-card {
	background-color: white;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-color-2) !important;
	/*
	padding-top: 100%;
	*/
	text-decoration: none;
	flex-direction: column;
}

.webcontainer .update-preview-card {
	background-color: white;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--text-color-2);
	/*
	padding-top: 100%;
	*/
	text-decoration: none;
	flex-direction: column;

	text-align: center;
}


.webcontainer .faq-card:hover {
	transition: all .3s, width .3s cubic-bezier(.2,0,0,1) 0s;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12)
}

.webcontainer .update-preview-card:hover {
	transition: all .3s, width .3s cubic-bezier(.2,0,0,1) 0s;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12)
}





.webcontainer .hero {
	flex: 0 0 476px;
	white-space: nowrap;
	overflow: hidden;
	text-align: center;
	padding-left: 50px;

	/*
	 * Fade bg?
	 */
	background-color: aquamarine;
}

.webcontainer .hero > img {
	display: inline-block;
	vertical-align: middle;
	margin-left: 50px;
	border-radius: 15px;
	border: 1px solid rgba(0, 0, 0, .10);

	width: 1000px;
}

.webcontainer .herotext {
	display: inline-block;
	text-align: center;
	vertical-align:middle;
}

.webcontainer .herotext a {
	/*
	* Experiment w/ this:
	* text-decoration: none;
	*/
	color: var(--success-color);
  text-decoration: underline;
}


.webcontainer .herotext > h1 {
	font-size: 48px;
	margin: 0px;
}

.webcontainer .herotext > h2 {
	margin: 0px;
}

.webcontainer .herotext > p {
	margin: 10px;
	font-size: 18px;
}

/*
 * PLAN: MAKE CARDS ALSO GRID CARDS!!!
 */
.webcontainer .updates-grid {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(200px, 400px));

	grid-auto-rows: auto;

	grid-gap: 2.5rem;

	padding-bottom: 40px;
}

.webcontainer .update-preview-grid {
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(auto-fit, 300px);

	grid-auto-rows: auto;

	grid-gap: 2.5rem;

	padding-bottom: 40px;
}

.webcontainer .sectionWrapper {
	max-width: var(--max-content-width);
	margin: auto;
}

.webcontainer hr {
	border: none;
	height: 0px;
	border-bottom: 1px solid var(--divider-color);

	width: 100%;
	max-width: var(--max-content-width);

	margin-top: 0px;
	margin-bottom: 0px;
}

.webcontainer .reasons-grid {
	display: grid;

	grid-template-rows: 20px auto;

	grid-template-columns: repeat(auto-fit, minmax(100px, 500px));
	grid-gap: 2.5rem;
	justify-content: center;
	padding-bottom: 2.5rem !important;
}

.webcontainer .reasons-grid h2 {
	grid-column: 1 / -1;
}

.webcontainer .updates h2 {
	text-align: center;
}


.webcontainer .abouthero {
	flex: 0 0 200px;
	text-align: center;

	/*
	 *Centers text
	 */
	display: flex;
	justify-content: center;
	align-items: center;

	/*
	 * Fade bg?
	 */
	background-color: turquoise;

	flex-direction: column;

	padding: 10px 40px 10px 40px;
}

.webcontainer .abouthero h1 {
	margin: 0px;
}

.webcontainer .abouthero p {
	max-width: 800px;
}

.webcontainer .opensrc h2 {
	text-align: center;
	margin-bottom: 7px;
}

.webcontainer .opensrc > p {
	text-align: center;
	margin-top: 8px;
	margin-bottom: 30px;
}

.webcontainer .opensrc-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(100px, 500px));
	grid-gap: 2.5rem;
	justify-content: center;
}
.webcontainer .opensrc-grid a {
	text-decoration: none;
	color: black;
}

.webcontainer .opensrc-card {
	background-color: white;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.webcontainer .opensrc-card:hover {
	transition: all .3s, width .3s cubic-bezier(.2,0,0,1) 0s;
	box-shadow: 0 4px 6px -0px rgba(0,0,0,.12)
}





/*
 * Features pg
 */

.webcontainer .features-hero {
	flex: 0 0 476px;
	white-space: nowrap;
	overflow: hidden;
	text-align: center;
	padding: 0px;
	padding-left: 50px;


	padding-top: 25px;
	padding-bottom: 25px;
}

.webcontainer .features-left-hero {
	/*
	 * Fade bg?
	 */
	background-color: aquamarine;


}

.webcontainer .features-right-hero {
	/*
	 * Fade bg?
	 */
	background-color: turquoise;
}

.webcontainer .features-hero > img {
	display: inline-block;
	vertical-align: middle;

	border-radius: 15px;
	border: 1px solid rgba(0, 0, 0, .10);
}


.webcontainer .features-left-hero > img {
	margin-left: 50px;
}

.webcontainer .features-right-hero > img {
	margin-left: -340px;
}

.webcontainer .features-hero > .features-article {

	display: inline-block;
	vertical-align:middle;
	max-width: 400px;
	white-space: normal;
}

.webcontainer .features-left-hero > .features-article {
	text-align: left;
}

.webcontainer .features-right-hero > .features-article {

	text-align: right;
	margin-left: 50px;
}


/*
 * Updates
 */

.webcontainer .update-card {
	background-color: white;
	min-height: 500px;
	max-width: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	/*
	padding-top: 100%;
	*/

	flex-direction: column;
	padding-bottom: 20px;
}

.webcontainer .update-card > h2 {
	margin: 16.6px;
	margin-bottom: 0px;
}

.webcontainer .meta {
	color: rgba(0, 0, 0, .6);
	margin: 0px;
	margin-top: 5px;
	margin-bottom: 10px;

}


.webcontainer .update-card  > .update-card-content {
	flex: 1;
	margin: 0px 24px;
}

.webcontainer .update-card h2 {
	text-align: center;
}


/*
 * Technical writing
 */

 /*
  * Used to represent text that appears in the UI.
  */
.webcontainer .uitext {
	font-weight: bold;
}

.webcontainer .faqsection {
	padding: 10px 40px 10px 40px;
	background-color: white;
	flex: 1;
}

.webcontainer .sectionWrapper {
	height: 100%;
}

.webcontainer aside {
	float: left;
	display: block;
	width: 150px;
	height: 100%;

	border-right: 1px solid var(--divider-color);

	margin-right: 20px;
}


.webcontainer aside > nav > ul {
	list-style-type: none;
	padding-left: 15px;
	border-left: 1px solid var(--divider-color);
}

/*
 * used to line up h3 with h1
 */
.webcontainer aside > nav > h3 {
	height: 22px;
	margin-top: 42.9px;
	margin-bottom: 24.9px;
}

/*
 * Plan: Standardize all links across app?
 */
.webcontainer aside > nav > ul a {
	text-decoration: none;
	color: var(--primary-color);
}

.webcontainer aside > nav > ul a.active {
	color: var(--success-color);
}

.webcontainer aside > nav > ul a:hover {
	text-decoration: underline;
	color: var(--success-color);
}

.webcontainer .faqcontent {
	overflow: hidden;
}

.webcontainer .faqcontent > h2 {
	margin-bottom: 0px;
}

.webcontainer .faqcontent > h4 {
	margin: 10px 0px 0px 0px;
}
.webcontainer .faqcontent > p {
	margin: 5px 0px 0px 0px;
}

.webcontainer .faqcontent > .context {
	margin-top: 0px
}

.webcontainer .note {
	background-color: #e7eeed;
	border-left: solid 4px var(--primary-color);
	line-height: 30px !important;
	padding: 12px;
}


/*
 * About Page
 */
.webcontainer .itemname {
	font-weight: bold;
}

.webcontainer .contentgroup {
	margin: 25px 0px;
}
.webcontainer .contentgroup * {
	margin: 0px 0px;
}

.webcontainer * {
  box-sizing: initial;
}
.webcontainer :not(ul, ol, p, li, a, span, .navright, .subnav, .subnav-content) {
    line-height: initial;
}
